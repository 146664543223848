import * as url from 'node:url'

import {
  CalendarDate,
  getLocalTimeZone,
  parseAbsoluteToLocal,
  parseDate,
  toCalendarDate,
} from '@internationalized/date'
import * as Sentry from '@sentry/browser'
import { endOfDay } from 'date-fns'
import { z, ZodError } from 'zod'

export function emptyStringToNull() {
  return z.preprocess((argument) => {
    if (typeof argument === 'string' && argument.trim() === '') {
      return null
    }
    return argument
  }, z.string().nullable())
}

export function nullToUndefined<T extends z.ZodTypeAny>(schema: T) {
  return z.preprocess((argument) => {
    if (argument === null) {
      return
    }
    return argument
  }, schema)
}

export function nullishToEmptyString() {
  return z.preprocess((argument) => {
    if (argument === undefined || argument === null) {
      return ''
    }
    return argument
  }, z.string())
}

export function transformNullishToEmptyString() {
  return z
    .string()
    .nullish()
    .transform((argument) => {
      if (argument === undefined || argument === null) {
        return ''
      }
      return argument
    })
}

export function secondsToMilliseconds() {
  return z.number().transform((dateS) => dateS * 1000)
}

export function dateToSeconds() {
  return z
    .date()
    .transform((dateS) => Math.floor(endOfDay(dateS).getTime() / 1000))
}

export function secondsToDate() {
  return secondsToMilliseconds().pipe(z.coerce.date())
}

export function phpDateToDate() {
  return z.object({ date: z.coerce.date() }).transform(({ date }) => date)
}

export function stringToCalendarDate() {
  return z.string().transform((value) => parseDate(value))
}

export function dateToCalendarDate() {
  return z.coerce
    .date()
    .transform((value) =>
      toCalendarDate(parseAbsoluteToLocal(value.toISOString())),
    )
}

export function calendarDateToIso() {
  return z.instanceof(CalendarDate).transform((date) => date.toString())
}

export function calendarDateToSeconds() {
  return z
    .instanceof(CalendarDate)
    .transform((dateS) =>
      Math.floor(endOfDay(dateS.toDate(getLocalTimeZone())).getTime() / 1000),
    )
}

export function parseRecover<T extends z.ZodTypeAny>(schema: T, data: unknown) {
  try {
    return schema.parse(data) as z.infer<T>
  } catch (error_) {
    Sentry.captureException(error_)
    console.error('zod error', url, error_.format())
    return data as z.infer<T>
  }
}

export function isZodError(error: unknown): error is ZodError {
  return Boolean(
    error &&
      (error instanceof ZodError || (error as ZodError).name === 'ZodError'),
  )
}
